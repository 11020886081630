var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stamp"},[_vm._l((_vm.marks),function(mark){return _c('ul',{key:mark.id},[_c('li',[_c('img',{attrs:{"src":'/assets/' + mark.mst_mark_path,"alt":mark.mst_mark_name}})])])}),_c('span',[(_vm.marks.length > 0)?_c('label',{staticClass:"open-button",attrs:{"for":'trigger-3-' + _vm.entryId}},[_vm._v(_vm._s(_vm.strMarksRepresentative))]):_vm._e()]),_c('div',{staticClass:"modal-wrap"},[_c('input',{attrs:{"id":'trigger-3-' + _vm.entryId,"type":"checkbox"}}),_c('div',{staticClass:"modal-overlay"},[_c('label',{staticClass:"modal-trigger",attrs:{"for":'trigger-3-' + _vm.entryId}}),_c('div',{staticClass:"modal-content"},[_c('label',{staticClass:"close-button",attrs:{"for":'trigger-3-' + _vm.entryId}},[_vm._v("✖️")]),_c('h3',[_vm._v("リアクション "+_vm._s(_vm.marks.length))]),_c('ul',{staticClass:"stamp-who"},_vm._l((_vm.marks),function(mark,index){return _c('li',{key:index},[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"image"},[_c('c-account-anchor-image',{attrs:{"has-img":mark.account_has_img,"account-id":mark.account_id,"account-name":mark.account_name}})],1),_c('span',[_vm._v(_vm._s(mark.account_name)+"("+_vm._s(mark.nickname)+")")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'クリックすると削除します',
                autoHide:false,
                loadingContent: 'Please wait...',
                loadingClass: 'content-is-loading',
              }),expression:"{\n                content: 'クリックすると削除します',\n                autoHide:false,\n                loadingContent: 'Please wait...',\n                loadingClass: 'content-is-loading',\n              }"}],staticClass:"icon-action",on:{"click":function($event){return _vm.deleteMark(mark.id)}}},[_c('img',{attrs:{"src":'/assets/' + mark.mst_mark_path,"alt":mark.mst_mark_name}})])])}),0)]),_vm._m(0)])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"balloon"},[_vm._v("hogehoge"),_c('br'),_vm._v("hogehoge"),_c('br'),_vm._v("hogehoge"),_c('br'),_vm._v("hogehoge")])
}]

export { render, staticRenderFns }