import Vue from 'vue'
import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import _ from 'lodash'
import router from '../router/index.js'
import Global from '../js/constant.js'
// modules
import filter from './entry-filter.js'
Vue.use(Vuex)

const store = new Vuex.Store({
  state:{
    loginFlg : false,
    sessionId : null,
    autoId : null,
    host:null,
    account : null,
    temporaryAccount : null,
    mail : null,
    myCircles : null,
    accountFollows : [],
    circleFollows : [],

    // リロード系
    entryReloadFlg : false,
    entryReloadId: null,
    commentReloadEntryId : null,

    // ひろば
    hashtagDivisionId : Global.hashtag_division_id.home, //ひろば ハッシュタグ区分 デフォルト：ホーム
    hashtagId: null,       //ひろば ハッシュタグID
    hashtagIds: [],        //ひろば 最近のハッシュタグ５件
    sectionHashtagsReloadFlg : false,
    // マイスペース
    myspaceDivisionId : Global.myspace_division_id.home, //myspace
    markId : null,         //myspace
    followCount : 0,       //myspace
    followerCount : 0,     //myspace
    // サークル
    circleMode:Global.circle_mode.public,           //サークル モード（public/private）
    circleHashtagDivisionId : Global.hashtag_division_id.home, //サークル ハッシュタグ区分 デフォルト：ホーム
    circleHashtagId: null,       //サークル ハッシュタグID


    keywords: null,
    keywordSearchEntries: null,
    // searchContainerDisplay:false,
    clientErrors: null,

    // TODO：あとでまとめる
    circleEditCircleId:null,
    circleEditPublish:null,
    circleEditCircleMembers:null,
    circleEditTabId : Global.settings_tab_id.circle,
    // circleEdit : {
    //   circleId: null,
    //   members: null,
    //   tabId: null,
    // },

    keywordSearchContainerDisplay : false,
    // お知らせ
    notificationContainerDisplay : false,
    notifications:[],
    entryEditContainerDisplay: false,
    entryEditContainerContext: {
      entry: null,
    },
    commentEditContainerDisplay: false,
    commentEditContainerContext: {
      parentEntryId: null,
      entry: null,
    },
    allAccounts: {},
    circles: {
      // circleId1: {id: 0, name: xxx}
      // circleId1: {id: 1, name: xxx}
    },
    followers: {},
    hashtags: {
      square: null,
      mypage: null,
    },
    // お気に入りサークル
    referCircles: [],
  },
  getters: {
    loginFlg(state) { return state.loginFlg },
    sessionId(state) { return state.sessionId },
    autoId(state) { return state.autoId },
    entryReloadFlg(state) { return state.entryReloadFlg },
    hashtagDivisionId(state) { return state.hashtagDivisionId },
    hashtagId(state) { return state.hashtagId },
    hashtagIds(state) { return state.hashtagIds },
    myspaceDivisionId(state) { return state.myspaceDivisionId },
    markId(state) { return state.markId },
    followCount(state) { return state.followCount },
    followerCount(state) { return state.followerCount },
    circleMode(state) { return state.circleMode },
    circleHashtagDivisionId(state) { return state.circleHashtagDivisionId },
    circleHashtagId(state) { return state.circleHashtagId },
  },
  // stateを更新するために用いられます
  mutations:{
    setLoginFlg(state, value) {
      state.loginFlg = value
    },
    setSessionId(state, value) {
      state.sessionId = value
    },
    setAutoId (state, value) {
      state.autoId = value
    },
    setHost(state, value) {
      state.host = value
    },
    setAccount (state, value) {
      state.account = value
      state.account['name'] = state.account.last_name + state.account.first_name
    },
    setTemporaryAccount (state, value) {
      state.temporaryAccount = value
    },
    setMail(state, value) {
      state.mail = value
    },
    setMyCircles(state, value) {
      state.myCircles = value
    },
    setAccountFollows(state, value) {
      state.accountFollows = value
      state.followCount = state.accountFollows.length + state.circleFollows.length
    },
    setCircleFollows(state, value) {
      state.circleFollows = value
      state.followCount = state.accountFollows.length + state.circleFollows.length
    },
    setEntryReloadFlg(state, value) {
      state.entryReloadFlg = value
    },
    setEntryReloadId(state, value) {
      state.entryReloadId = value
    },
    setCommentReloadEntryId(state, value) {
      state.commentReloadEntryId = value
    },
    setHashtagDivisionId (state, value) {
      state.hashtagId = null
      state.hashtagIds = []
      state.hashtagDivisionId = value
    },
    setHashtagId (state, value) {
      state.hashtagId = value
    },
    setHashtagIds (state, value) {
      state.hashtagIds = value
    },
    setSectionHashtagsReloadFlg (state, value) {
      state.sectionHashtagsReloadFlg = value
    },
    setMyspaceDivisionId (state, value) {
      state.myspaceDivisionId = value
    },
    setMarkId (state, value) {
      state.markId = value
    },
    setFollowCount (state, value) {
      state.followCount = value
    },
    setFollowerCount (state, value) {
      state.followerCount = value
    },
    setCircleMode (state, value) {
      state.circleHashtagDivisionId = 1
      state.circleHashtagId = null
      state.circleMode = value
    },
    setCircleHashtagDivisionId (state, value) {
      state.circleHashtagId = null
      state.circleHashtagDivisionId = value
    },
    setCircleHashtagId (state, value) {
      state.circleHashtagId = value
    },
    setKeywords (state, value) {
      state.keywords = value
    },
    setKeywordSearchEntries (state, value) {
      if (value.length == 0) {
        state.keywordSearchEntries = []
        return
      }
      state.keywordSearchEntries = state.keywordSearchEntries.concat(value)
    },
    setClientErrors (state, value) {
      state.clientErrors = value
    },
    setCircleEditCircleId (state, value) {
      state.circleEditCircleId = value
    },
    setCircleEditPublish (state, value) {
      state.circleEditPublish = value
    },
    setCircleEditCircleMembers (state, value) {
      state.circleEditCircleMembers = value
    },
    concatCircleEditCircleMembers (state, value) {
      if (state.circleEditCircleMembers == null) {
        state.circleEditCircleMembers = value
        return
      }
      state.circleEditCircleMembers = state.circleEditCircleMembers.concat(value)
    },
    deleteCircleEditCircleMembersByRequestId (state, value) {
      state.circleEditCircleMembers = _.filter(state.circleEditCircleMembers, (row) => { return row.circle_request_id != value})
    },
    setCircleEditTabId (state, value) {
      state.circleEditTabId = value
    },
    setKeywordSearchContainerDisplay (state, value) {
      state.keywordSearchContainerDisplay = value
    },
    setNotificationContainerDisplay (state, value) {
      state.notificationContainerDisplay = value
    },
    setNotifications (state, value) {
      state.notifications = value
    },
    setEntryEditContainerDisplay (state, value) {
      state.entryEditContainerDisplay = value
    },
    setEntryEditContainerContext (state, value) {
      state.entryEditContainerContext = value
    },
    initEntryEditContainerContext (state) {
      state.entryEditContainerContext = {
        entry: null,
      }
    },
    setCommentEditContainerDisplay (state, value) {
      state.commentEditContainerDisplay = value
    },
    setCommentEditContainerContext (state, value) {
      state.commentEditContainerContext = value
    },
    initCommentEditContainerContext (state) {
      state.commentEditContainerContext = {
        parentEntryId: null,
        entry: null,
      }
    },
    setAllAccounts (state, value) {
      state.allAccounts = value
    }, 
    setCircles (state, value) {
      state.circles = value
    },
    setFollowers (state, value) {
      state.followers = value
    },
    setSqureHashtags (state, value) {
      state.hashtags.square = value
    },
    setMypageHashtags (state, value) {
      state.hashtags.mypage = value
    },
    setReferCircles (state, value) {
      state.referCircles = value
    },
    restore (state, { sessionId, autoId, account, temporaryAccount, mail, clientErrors, host }) {
      if ( state.sessionId == null ) {
        state.sessionId = sessionId
      }
      state.autoId           = autoId
      state.account          = account
      state.temporaryAccount = temporaryAccount
      state.mail             = mail
      state.clientErrors     = clientErrors
      state.host             = host
    },
    initState (state) {
      state.loginFlg = false
      // state.sessionId = null
      state.autoId = null
      state.account = null
      state.host = null
      state.mail = null
      state.myCircles = null
      state.accountFollows = []
      state.circleFollows = []
      state.entryReloadFlg = false
      state.commentReloadEntryId = null
      state.hashtagDivisionId = Global.hashtag_division_id.home
      state.hashtagId = null
      state.hashtagIds = []
      state.sectionHashtagsReloadFlg = false
      state.myspaceDivisionId = Global.myspace_division_id.home
      state.markId = null
      state.followCount = 0
      state.followerCount = 0
      state.circleMode = Global.circle_mode.public
      state.circleHashtagDivisionId = Global.hashtag_division_id.home
      state.circleHashtagId = null
      state.keywords = null
      state.keywordSearchEntries = null
      state.clientErrors = null
      state.circleEditCircleId = null
      state.circleEditPublish = null
      state.circleEditCircleMembers = null
      state.circleEditTabId = Global.settings_tab_id.circle
      state.keywordSearchContainerDisplay = false
      state.notificationContainerDisplay = false
    }
  },
  // ステートから別の値を算出されるために用いられます
  // 非同期処理や外部APIとの通信を行い、最終的にミューテーションを呼び出すために用いられます
  // store.dispatch('incrementAction')
  actions:{
    // {}はctx(context)を表しstate,getters.dispatch,commitを保持する
    save ({state}) {
      const data = {
        sessionId: state.sessionId,
        autoId: state.autoId,
        account: state.account,
        temporaryAccount: state.temporaryAccount, 
        mail: state.mail,
        clientErrors: state.clientErrors,
        host: state.host,
      }
      localStorage.setItem(Global.session_key, JSON.stringify(data))
    },
    restore ({commit}) {
      const data = localStorage.getItem(Global.session_key)
    
      if (data) {
        commit('restore', JSON.parse(data))
      }
    },
    storageClear () {
      localStorage.clear()
    },
    getMyCircles ({state, commit}) {
      if (!state.account) return 
      axios.post(Global.url.api_circle_members_search_by_account_id, { account_id: state.account.id })
      .then(res => {
        if (res.data.status == Global.api_status.success) {
          commit('setMyCircles', res.data.circle_members)
        }
      }).catch(error => {
        console.dir(error)
      })
    },
    getAccountFollows ({state, commit}) {
      if (!state.account) return 
      axios.post(Global.url.api_account_follows_search, { account_id: state.account.id })
      .then(res => {
        if (res.data.status == Global.api_status.success) {
          commit('setAccountFollows', res.data.account_follows)
        }
      }).catch(error => {
        console.dir(error)
      })
    },
    getCircleFollows ({state, commit}) {
      if (!state.account) return 
      axios.post(Global.url.api_circle_follows_search, { account_id: state.account.id })
      .then(res => {
        if (res.data.status == Global.api_status.success) {
          commit('setCircleFollows', res.data.circle_follows)
        }
      }).catch(error => {
        console.dir(error)
      })
    },
    getAllAccounts ({commit}) {
      axios.post(Global.url.api_accounts_all, {}).then(res => {
        if (res.data.status === Global.api_status.success) {
          commit('setAllAccounts', res.data.accounts)
          return
        }
      })
      .catch(error=>{
        console.dir(error)
      })
    },
    getCircles ({state, commit}) {
      let params = {}
      if (state.account != null) params['account_id'] = state.account.id
      
      axios.post( Global.url.api_all_circles, params).then(res => {
        if (res.data.status == Global.api_status.success) {
          commit('setCircles', res.data.circles)
        }
      }).catch(error=>{
        console.dir(error)
      })
    },
    getFollowers ({state, commit}) {
      if (!state.account) return 
      axios.post(Global.url.api_account_followers_search, {account_id: state.account.id})
      .then (res => {
        if (res.data.status == Global.api_status.success) {
          commit('setFollowers', res.data.account_follows)
          // commit('setFollowerCount', res.data.account_follows)
        }
      })
      .catch (error => { console.log(error) })
    },
    getReferCircles ({state, commit}) {
      if (!state.account) return 
      axios.post(Global.url.api_refer_circles_search, {account_id: state.account.id})
      .then (res => {
        if (res.data.status == Global.api_status.success) {
          commit('setReferCircles', res.data.refer_circles)
        }
      })
      .catch (error => { console.log(error) })
    },
  },
  modules: {
    filter,
  }
})

sync(store, router)

export default store
  