//export const filter ={
const filter = {
  state: {
    items: {
      entryAt: null,
      circleIds: null,
      hashtagIds: null,
      freeWords: null,  
    }
  },
  getters: {
    items(state) { return state.items },
  },
  mutations: {
    setItems (state, value) {
      state.items = value      
    },
    initItems (state) {
      state.items = {
        entryAt: null,
        circleIds: null,
        hashtagIds: null,
        freeWords: null,
      }
    },
  },
  actions: {},
  modules: {}
}
export default filter