var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"support"}},[_vm._m(0),_c('div',{staticClass:"container"},[_c('c-support-navigation'),_c('main',[_c('section',[_c('h1',[_vm._v("ともつくって？")]),_c('h2',[_vm._v("「ともつく」は、繋がりを続けて行くための、会話促進コミュニティサイトです。")]),_c('p',[_vm._v("「ともつく」は、")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(">>広場へ戻る")]),_c('br')],1)]),_c('section',[_c('h1',[_vm._v("メンバー登録方法")]),_vm._m(4),_vm._m(5),_c('h1',[_vm._v("つぶやいてみよう（投稿）")]),_vm._m(6),_vm._m(7),_c('p',{staticClass:"clear"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(">>広場へ戻る")]),_c('br')],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('h1',[_vm._v("ともつくネット - サポート")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("人々が、普段どんなことを考えたり感じたりしているか、お互いに分かるように、今の気持ちをぶつける場所です。")]),_c('li',[_vm._v("ともつくは、生活するときに必要になる情報の掲示板のような役割です。")]),_c('li',[_vm._v("ちょっと困った事があるときに、答えてくれる人を探すこともできます。")]),_c('li',[_vm._v("ゆくゆくは、島外からお送りします。訪れて、島のファンになってくださった人たちとも交流し続けることが簡単になるための会話の場になるようにもしたいと思っています。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("ユーザー登録など手間のかかることをしなくても、"),_c('br'),_vm._v(" まずは「広場」を見てみるだけでも、今、どんなことが起きているかが分かります。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("またユーザー登録していただければ、広場に自分でつぶやくことが出来たり、"),_c('br'),_vm._v(" 誰かの発言に対して、「いいね」や、「お疲れさま」といった、リアクションをすることができます。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"intro_step2"}},[_c('p',{staticClass:"imgleft"},[_c('img',{attrs:{"src":"/static/images/about/pix01.jpg","width":"446","height":"246"}})]),_c('p',[_vm._v("左上にある「新規ユーザー登録」というボタンをクリックします。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"intro_step3"}},[_c('p',{staticClass:"imgleft sp"},[_c('img',{attrs:{"src":"/static/images/about/pix02.jpg","width":"446","height":"388"}})]),_c('p',[_vm._v("①登録画面が現れますので、そこ四角の部分に自分の情報を入力します。")]),_c('p',[_vm._v("②「登録」ボタンを押します。")]),_c('p',[_vm._v("これで登録は完了です。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"intro_step4"}},[_c('p',{staticClass:"imgleft"},[_c('img',{attrs:{"src":"/static/images/about/pix03.jpg","width":"446","height":"239"}})]),_c('p',[_vm._v("登録が完了したら実際につぶやいてみましょう。")]),_c('p',[_vm._v("赤線内がつぶやきスペースです。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"intro_step5"}},[_c('p',{staticClass:"imgleft sp"},[_c('img',{attrs:{"src":"/static/images/about/pix04.jpg","width":"446","height":"177"}})]),_c('p',[_vm._v("①つぶやきスペースに入力"),_c('br'),_vm._v(" ②本文中に絵文字を挿入"),_c('br'),_vm._v(" ③写真をアップロード"),_c('br'),_vm._v(" ④特定の人宛の宛先を追加"),_c('br'),_vm._v(" ⑤話題を選択、または新規作成"),_c('br'),_vm._v(" ⑥入力完了後ボタンを押してつぶやく")])])
}]

export { render, staticRenderFns }