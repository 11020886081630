<template>
  <div id="openspace">
    <div class="message"><a class="close" href=""><img src="/assets/icon-close-min.svg" alt="閉じる">閉じる</a>
      <div class="container"><img src="/assets/logo.svg" alt="ともつくネット">
        <p>ともつくネットは、さとのば大学に集う方達のための回覧板です。<br>講義についてはもちろん、日々の学びについての質問やオフ会などの話題も大歓迎です。</p><a href="">発言またはサークルに参加するには、メンバー登録</a>
      </div>
    </div>
    <div id="container">
      <div class="header">
        <div class="wrapper">
          <h1>ひろば</h1>
          <div>Open Space</div>
        </div>
      </div>
      <!-- ホーム、公式な話題、自由な話題、最近の話題 -->
      <c-section-hashtag-division />
      <!-- 話題リスト -->
      <c-section-hashtag />
      <div class="wrapper">
        <aside>
          <div class="widget">
            <c-side-menu />
          </div>
        </aside>
        <main class="timeline">
          <c-entry-filter />
          <!-- つぶやきコンポーネント -->
          <c-tweet :entry-division-id="this.entryDivisionId" />
          <!-- つぶやき一覧 -->
          <c-entry v-for="(entry, index) in entries" :key="index" :entry-division-id="entryDivisionId" :entry="entry" :mode="entry_display_mode"></c-entry>
          <div v-show="entries.length == 0 ">投稿されていません</div>
          <button v-show="moreButton" @click="() => getEntries()">さらに見る</button>
          <button v-show="moreFilterButton" @click="next">さらに見る(filter)</button>
          <div v-show="loadingFlg" style="width: 100%;text-align: center;"><img src="/assets/icon_loading.png" /></div>
        </main>
        <c-cotolike-widget />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Tweet from '../common/entry/tweet.vue'
import Entry from '../common/entry'
import Filter from '../common/entry/filter.vue'
import SectionHashtagDivision from './section-hashtag-division.vue'
import SectionHashtag from './section-hashtag.vue'
import CotolikeWidget from '../common/cotolike-widget.vue'
import SideMenu from '../common/side-menu'

export default {
  data () {
      return {
        entryDivisionId : this.Global.entry_division_id.square,
        entries: [],
        limit: this.Global.entry_limit_per_page,
        offset: 0,
        entry_display_mode: this.Global.entry_display_mode.time_line,
        entryMap: {}, // 分散取得のための状態管理オブジェクト
        filterOffset: 0,
        entryStocker: [],
        retryCount: 0,
        moreButton: true,
        moreFilterButton: false,
        loadingFlg: false
      }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'filter',
      'hashtagDivisionId',
      'hashtagId',
      'hashtagIds',
      'entryReloadFlg',
    ]),
  },
  
  mounted () {
    if (this.$route.params.hashtagId!=null) {
      this.setHashtagId(this.$route.params.hashtagId)
      return
    }
    this.getEntries()
  },

  watch : {
    loginFlg : {
      handler:function () {},
      deep:true
    },    
    // storeのhashtagDivisionIdを監視
    hashtagDivisionId (newValue) {
      if ( newValue != this.Global.hashtag_division_id.active ) {
        // this.entries = []
        // this.offset = 0
        // this.getEntries()
        this.setEntryReloadFlg(true)
      }
    },
    // storeのhashtagIdを監視
    hashtagId () {
      // this.entries = []
      // this.offset = 0
      // this.getEntries()
      this.setEntryReloadFlg(true)
    },
    hashtagIds () {
      // this.entries = []
      // this.offset = 0
      // this.getEntries()
      this.setEntryReloadFlg(true)
    },
    // こんな書き方もできる
    // '$store.state.filter.filters': {
    //   handler: function () {
    //     alert('フィルター変更！');
    //     console.dir(this.filters.freeWords)
    //   },
    //   deep: true
    // },
    'filter.items' (items) {
      if (!items.entryAt && !items.cicleIds && !items.hashtagIds && !items.freeWords) {
        this.setEntryReloadFlg(true)
        this.moreButton = true
        this.moreFilterButton = false
        return
      }
      console.dir(this.filter)
      this.moreButton = false
      this.doFilter()
    },
    entryReloadFlg (newValue) {
      if ( !newValue ) return
      this.entries = []
      this.offset = 0
      this.entryStocker = []
      this.entryMap = {}
      this.filterOffset = 0

      this.getEntries()
      this.setEntryReloadFlg(false)
    },
    $route() {
      this.setHashtagDivisionId(this.hashtagDivisionId)
      this.setHashtagId(this.$route.params.hashtagId)
    }
  },
  methods : {
    ...mapMutations([
      'setHashtagDivisionId',
      'setHashtagId',
      'setHashtagIds',
      'setEntryReloadFlg',
      'setClientErrors'
    ]),
    next: function () {
      console.log('------ next button ------')
      console.log(`filterOffset: ${this.filterOffset}`)
      console.log(`this.entries.length: ${this.entries.length}`)
      console.dir(this.entryStocker)

      while (this.entryStocker.length > 0) {
        this.entries.push(this.entryStocker.shift())

        if (this.entries.length == (this.filterOffset + this.limit)) {
          break
        } 
      }
      console.log('171')
      console.log(`filterOffset: ${this.filterOffset}`)
      console.log(`this.entries.length: ${this.entries.length}`)
      console.dir(this.entryStocker)
      console.log('173')
      if (this.entries.length == (this.filterOffset + this.limit)) {
        this.filterOffset = this.filterOffset + this.limit
        console.log('176')
        return
      }
      console.log('179')
      console.dir(this.entryStocker)
      console.dir(this.entries.length)
      console.log('182')
      this.getAndFilterEntries()
      // this.doFilter()
    },
    doFilter: function () {
      // console.log(`-----${this.offset}`)
      // 現在表示されているentryをフィルタリング
      let newEntries = []
      for (let entry of this.entries.filter(entry => this.findByCondition(entry))) {
        if (newEntries.length <= this.filterOffset + this.limit) {
          newEntries.push({...entry})
        } else {
          this.entryStocker.push({...entry})
        }
      }

      if (newEntries.length > 0) {
        this.entries = newEntries
      }

      this.getAndFilterEntries()
    },
    getEntries : function() {

      let params = this.createParams()
      
      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (this.entries.length > 0 && res.data.entries.length == 0) {
            this.$toasted.info("これ以上取得できません")
            return
          } 

          this.entries = this.entries.concat(res.data.entries)
          this.offset += this.limit
          return
        }
        this.$toasted.error("データ取得に失敗しました")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"square/index.vue::getEntries", params: params, errorMessage:error})
      })
    },
    createParams : function () {
      let params = {
        entry_division_id : this.Global.entry_division_id.square,
        hashtag_id : this.hashtagId,
        hashtag_ids : this.hashtagIds,
        limit : this.limit,
        offset : this.offset
      }
      if (this.account) {
        params['account_id'] = this.account.id
      }
      if ( this.hashtagDivisionId == this.Global.hashtag_division_id.official ) {
        params['hashtag_official'] = true
      } else if (this.hashtagDivisionId == this.Global.hashtag_division_id.free) {
        params['hashtag_official'] = false
      }
      return params
    },
    getAndFilterEntries : function() {
      this.loadingFlg = true
      // 10分割して検索するための状態管理オブジェクト作成
      for (let i = 0; i < 10; i++) {
        this.entryMap[`${parseInt(this.offset) + parseInt(i * this.limit)}`] = {
          status : 0,
          entries : null,
          commentMap: {}
        }
      }
      console.dir(this.entryMap)
      Object.keys(this.entryMap).forEach(offset => {
        // 1: entry検索中
        this.entryMap[`${offset}`].status = 1
        // ------ entry取得 ------
        let params = this.createParams()
        this.axios.post(this.Global.url.api_entries_search, params)
        .then(res => {
          if (res.data.status == this.Global.api_status.success) {
            // 2: entry取得完了
            this.entryMap[`${offset}`].status = 2
            if (res.data.entries.length > 0) {
              let _entries = [...res.data.entries]          
              this.entryMap[`${offset}`].entries = _entries
              // -------------- コメント取得 --------------
              // 3: コメント検索中
              this.entryMap[`${offset}`].status = 3
              // commentMapを初期化
              _entries.forEach(entry => this.entryMap[`${offset}`].commentMap[`${entry.id}`] = {status: 31});

              // 非同期でコメント取得
              _entries.forEach(entry => {
                this.axios.post(this.Global.url.api_entries_replies, { entry_id: entry.id })
                .then(res => {
                  this.entryMap[`${offset}`].commentMap[`${entry.id}`].status = 32
                  entry.comments = []
                  if ( res.data.status === this.Global.api_status.success ) {
                    if (res.data.entries.length > 0) {
                      entry.comments = [...res.data.entries] 
                    }
                    if (Object.values(this.entryMap[`${offset}`].commentMap).filter(r => r.status <= 31).length == 0) {

                      this.entryMap[`${offset}`].status = 4
                      let newEntries = this.entryMap[`${offset}`].entries.filter(entry => this.findByCondition(entry))

                      if (newEntries.length > 0) {
                        // this.entries = this.entries.concat([...newEntries])
                        for (let newEntry of newEntries) {
                          if (this.entries.length < this.filterOffset + this.limit) {
                            // console.log('304')
                            this.entries.push({...newEntry})
                          } else {
                            // console.log('307')
                            this.entryStocker.push({...newEntry})
                          }
                        }
                      }
                    }
                    if (Object.values(this.entryMap).filter(row => row.status == 4).length == Object.keys(this.entryMap).length) {
                      console.dir('100件分散検索終了')
                      // console.dir(this.entryStocker)
                      // console.log(this.entries.length)
                      this.loadingFlg = false

                      if (this.entries.length == this.filterOffset + this.limit) {
                        this.filterOffset = this.filterOffset + this.limit
                        this.retryCount = 0
                        this.moreFilterButton = true
                        console.log('reached 10 count!')
                        return
                      }

                      if (this.entries.length < this.filterOffset + this.limit) {
                        if (this.retryCount == 1) {
                          this.$toasted.info("これ以上取得できません")
                          this.moreFilterButton = false
                          return
                        }

                        this.offset = Object.keys(this.entryMap).pop()
                        console.log('343 -------- retry!!')
                        this.moreFilterButton = false
                        this.entryMap = {}
                        this.retryCount++
                        this.getAndFilterEntries()
                        return
                      }
                    }
                    
                    return
                  }
                  // this.loadingFlg = false
                })
                .catch(error=>{
                  this.$toasted.error('client error')
                  this.setClientErrors({func:"square/index.vue::getAndFilterEntries", params: params, errorMessage:error})
                })
              })
            }
            return
          }
          console.log('316')
          this.entryMap[`${offset}`].status = 99
          this.entryMap[`${offset}`].entries = null
          return
        })
        .catch(error=>{
          this.$toasted.error('client error')
          this.setClientErrors({func:"square/index.vue::getAndFilterEntries", params: params, errorMessage:error})
        })
      })
    },
    findByCondition: function (entry) {
      // console.log(`${entry.updated_at} - ${this.filter.items.entryAt} => ${String(entry.updated_at).includes(this.filter.items.entryAt)}`)
      // 日付
      if (String(entry.updated_at).includes(this.filter.items.entryAt)) {
        console.log('364')
        return true
      }
      // console.dir(entry)
      // コメント内フィルタリング
      if(entry.comments.filter(comment => {return this.filter.items.freeWords.some(word => comment.text.includes(word))}).length > 0) {
        return true
      }
      // つぶやきフィルタリング      
      if(this.filter.items.freeWords.some(word => entry.text.includes(word))) {
        return true
      }
      return false
    },
  },

  components : {
    'c-tweet' : Tweet,
    'c-entry' : Entry,
    'c-entry-filter' : Filter,
    'c-section-hashtag-division' : SectionHashtagDivision,
    'c-section-hashtag' : SectionHashtag,
    'c-cotolike-widget' : CotolikeWidget,
    'c-side-menu': SideMenu,
  }
}
</script>