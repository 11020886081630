<template>
  <div id="circle-main">
    <div class="header">
      <div class="Item-Content">
        <div class="Item-Content-Flg1" v-show="isPrivate()"></div>
        <div class="cover" style="background-image:url('/assets/welcome-bg@2x.jpg')">
          <div class="title" v-if="circle != null">
            <div class="icon">
              <c-circle-image :has-img="circle.has_img" :circle-id="circle.id" :circle-name="circle.circle_name" />
              <h1>{{circle.circle_name}}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <c-section-hashtag-division v-if="circle != null" :circle="circle" :member-flg="isMember" />
    <c-section-hashtag v-if="circle != null" :circle="circle" :member-flg="isMember" />
    <div class="wrapper">
      <aside>
        <div class="widget">
          <c-profile v-if="circle != null" :circle="circle" :circle-members="circleMemebers" />
          <c-followers v-if="account != null" :circle-id="circleId" />
        </div>
      </aside>
      <main class="timeline">
        <c-entry-filter />
        <c-tweet :entry-division-id="this.entryDivisionId" :circle-id="circleId" v-if="loginFlg && isMember" />
        <c-entry v-for="(entry, index) in entries" :key="index" :entry-division-id="entryDivisionId" :entry="entry" :mode="entry_display_mode" />
        <div v-show="entries.length == 0 ">投稿されていません</div>
        <button @click="()=>{ this.getEntries() }">さらに見る</button>
      </main>
      <c-cotolike-widget />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import Profile from './profile.vue'
import Followers from '../../common/followers.vue'
import SectionHashtagDivision from './section-hashtag-division.vue'
import SectionHashtag from './section-hashtag.vue'
import Tweet from '../../common/entry/tweet.vue'
import Entry from '../../common/entry'
import Filter from '../../common/entry/filter.vue'
import CotolikeWidget from '../../common/cotolike-widget.vue'

export default {
  data () {
      return {
        circleId : null,
        circle : null,
        circleMemebers : [],
        isMember : false,
        entries: [],
        limit: this.Global.entry_limit_per_page,
        offset: 0,
        entry_display_mode: this.Global.entry_display_mode.time_line,
      }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'circleMode',
      'circleHashtagDivisionId',
      'circleHashtagId',
      'entryReloadFlg',
    ]),
    entryDivisionId : function () {
      return this.getEntryDivisionId()
    },
  },
  created () {
  },
  mounted () {
    this.circleId = Number(this.$route.params.circleId)
    this.setCircleMode(Number(this.$route.params.circleMode))

    if(this.account == null && Number(this.$route.params.circleMode) == this.Global.circle_mode.private) {
      this.$toasted.error('ログインしていないので、広場に遷移しました')
      this.$router.push( { path : '/' }, () => {} )
    }
    this.getCircleInfo(this.circleId)
    if (this.$route.params.hashtagId != null) {
      this.setCircleHashtagId(this.$route.params.hashtagId)
      return
    }
    this.getEntries()
  },
  watch : {
    loginFlg () {
      this.isMember = _.filter(this.circleMemebers, {'account_id' : this.account.id}).length > 0
    },
    // circleMode () {
    //   this.entries = []
    //   this.offset = 0
    //   this.getEntries()
    // },
    circleHashtagDivisionId () {
      this.entries = []
      this.offset = 0
      this.getEntries()

    },
    // storeのhashtagIdを監視
    circleHashtagId () {
      this.entries = []
      this.offset = 0
      this.getEntries()
    },
    entryReloadFlg (newValue) {
      if ( !newValue ) return
      this.entries = []
      this.offset = 0
      this.getEntries()
      this.setEntryReloadFlg(false)
    },
    account (newAccount) {
      this.getCircleMember(newAccount)
    },
    $route() {
      // location.reload()
      if (this.$route.params.circleId != this.circleId) {
        this.getCircleInfo(this.$route.params.circleId)
        return
      }
      this.circleId = Number(this.$route.params.circleId)
      this.setCircleMode(Number(this.$route.params.circleMode))
      // this.getCircleInfo(this.circleId)
      this.setCircleHashtagId(this.$route.params.hashtagId)
    }
  },
  methods : {
    ...mapMutations([
      'setCircleMode',
      'setCircleHashtagDivisionId',
      'setCircleHashtagId',
      'setEntryReloadFlg',
      'setClientErrors',
    ]),
    getCircleInfo : function (circleId) {
      this.axios.get(this.Global.url.api_circles_show + circleId)
      .then(res => {
        if ( res.data.status == this.Global.api_status.success ) {
          this.circle = res.data.circle
          console.dir(this.circle)
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/time-line/main.vue::getCircleInfo", params: {}, errorMessage:error})
      }).finally(()=>{ 
        this.getCircleMember(circleId)
      })
    },
    getCircleMember : function (circleId) {
      let params = { circle_id : circleId }
      this.axios.post(this.Global.url.api_circle_members_search, params)
      .then(res => {
        if ( res.data.status == this.Global.api_status.success ) {
          this.circleMemebers = res.data.circle_members

          if (this.loginFlg) {
            this.isMember = _.filter(this.circleMemebers, {'account_id' : this.account.id}).length > 0
          }
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/time-line/main.vue::getCircleMember", params: params, errorMessage:error})
      }).finally(()=>{  })
    },
    getEntries : function() {
      let params = {
        entry_division_id : this.getEntryDivisionId(),
        circle_id : this.circleId,
        hashtag_id : this.circleHashtagId,
        hashtag_official : this.getHashtagOffcial(),
        limit : this.limit,
        offset : this.offset
      }
      if (this.account) {
        params['account_id'] = this.account.id
      }
      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (this.entries.length > 0 && res.data.entries.length == 0) {
            this.$toasted.info("これ以上取得できません")
            return
          } 
          this.entries = this.entries.concat(res.data.entries)
          this.offset += this.limit
          return
        }
        this.$toasted.error("データ取得に失敗しました")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/time-line/main.vue::getEntries", params: params, errorMessage:error})
      })
    },
    getEntryDivisionId : function () {
      if (this.circleMode === this.Global.circle_mode.public) return this.Global.entry_division_id.circle
      if (this.circleMode === this.Global.circle_mode.private) return this.Global.entry_division_id.private_circle
    },
    getHashtagOffcial : function () {
      if ( this.circleHashtagDivisionId == this.Global.hashtag_division_id.official ) return true
      if ( this.circleHashtagDivisionId == this.Global.hashtag_division_id.free ) return false
    },
    isEdittable : function (circle) {
      if (!this.loginFlg) return false
      if (!this.account) return false
      if (this.divisionId == this.Global.circles_division_id.manage) return true
      return _.filter(circle.members, (row)=>{  return this.account.id == row.id && row.admin_flg }).length > 0
    },
    isPrivate : function () {
      return this.circleMode === this.Global.circle_mode.private
    }
  },
  components : {
    'c-profile' : Profile,
    'c-followers' : Followers,
    'c-section-hashtag-division' : SectionHashtagDivision,
    'c-section-hashtag' : SectionHashtag,    
    'c-tweet' : Tweet,
    'c-entry' : Entry,
    'c-entry-filter' : Filter,
    'c-cotolike-widget' : CotolikeWidget,
  }
}
</script>
