<template>
  <div>
    <div class="detail-search">
      <input type="text" v-model="dispWords" v-bind:disabled="true" />
      <button @click="clickButton">
        <img src="/assets/icon-settings.svg" alt="詳細検索">
      </button>
    </div>
    <div class="action-overlay" v-show="showFlg">
      <div class="container">
        <p>移動先</p>
        <div class="item">
          <input type="date" placeholder="" v-model="entryAt"><span class="focusborder"></span>
        </div> 
        
        <div class="item">
          <p>サークル</p>
          <input type="text" placeholder="入力する"><span class="focusborder"></span>
        </div> 

        <div class="item">
          <p>話題</p>
          <input type="text" placeholder="入力する"><span class="focusborder"></span>
        </div> 
        <div class="item">
          <p>フリーワード</p>
          <input type="text" placeholder="入力する" v-model="freeWords"><span class="focusborder"></span>
        </div> 
        
        <div class="action">  
          <button class="cancel" @click="clickButton">戻る</button>
          <button class="confirm" @click="doFilter">フィルタ実行</button>
        </div>
      </div>
    </div>
  </div>  
</template>
<script>
import {mapState, mapMutations} from 'vuex'
// import ErrorHandler from '../../../js/error-handler'
// import mst_topics from '../../../js/api/mst-topics'

export default {
  props : {
    circleId :{
      type:Number,
      required:false
    },
  },
  data () {
    return {
      showFlg:false,
      dispWords: null,
      entryAt: null,
      circleIds: null,
      hashtagIds: null,
      freeWords: null,
      hashtagName:null,
      dialogDisplay:false,
      saveButtonDisplay:false
    }
  },
  computed:{
    ...mapState([
      'circleMode',
      'filter',
    ]),
  },
  mounted : function () {

  },
  methods:{
    ...mapMutations([
      'setItems',
      'initItems',
      'setSectionHashtagsReloadFlg',
      'setClientErrors'
    ]),
    clickButton: function() {
      this.showFlg = !this.showFlg
    },
    doFilter: function() {
      this.dispWords = this.freeWords
      let _freeWords = null
      if (this.freeWords) {
        // eslint-disable-next-line
        _freeWords = this.freeWords.replace(/　/g," ").split(' ')
      }

      this.setItems({
        entryAt: this.entryAt,
        circleIds: this.circleId,
        hashtagIds: this.hashtagIds,        
        freeWords: _freeWords,
      })
      this.showFlg = !this.showFlg
    }
  },
}
</script>